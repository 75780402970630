<template>
    <div>
        <div class="modulo_page_header">
            <div class="container">
                <div class="row flex justify-center md:justify-start">
                    <div class="title_head_interna">
                        <div class="btn_voltar">
                            <a href="javascript:history.back()"><span class="jam jam-arrow-left"></span></a>
                        </div>
                            <div>
                            <h1 class="leading-relaxed">Notícias</h1>
                            <!-- <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
                        </div>
        
                    </div>
                </div>
            </div>
        </div>

        <div class="page_internas">
            <div class="container" v-if='!waiting'>
                <div class="row">
                    <div class="col-md-12">

                        <div class="mais_noticias">

                            <div v-for='(noticia) in outrasNoticias' :key='noticia.id'>
                            <ul>
                                <li>
                                    <a @click="$router.push(`/noticia/${ noticia.id }/`)">{{ noticia.title }}</a>
                                    <p class="chamada_data d-flex justify-content-start align-items-center"> <span class="jam jam-calendar"></span>{{ noticia.publish_date_f }}</p>
                                </li>
                            </ul>

                            </div>
                        </div>
                    </div>
                    <!--
                    <div class="flex justify-center w-100">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item"><a class="page-link" href="#">Anterior</a></li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">Próximo</a></li>
                            </ul>
                        </nav>
                    </div>
                    -->
                </div>
            </div>
            <div v-else>
                <center>
                    <div id="loading" class="loader"></div>
                </center>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
    .textsize {
        font-size: 2rem;
        font-weight: 800;
    }
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
    }
</style>

<script>
import Noticias from '../../middleware/controllers/Noticias';
import configs from '../../middleware/configs';
import moment from 'moment';
import NovaAPI from '../../middleware/controllers/NovaAPI.js'

export default {
    data() {
        return {
            noticia: {},
            outrasNoticias: [],
            waitingNoticia: true,
            waiting: true,
        }
    },
    created() {
        NovaAPI.authenticateApp(configs.novaAPI.applicationId, configs.novaAPI.applicationToken)
        .then(response => {
            this.getAllNews(response);
        })
    },
    methods: {
        getAllNews(apiToken) {
            Noticias.getAllNews(apiToken)
            .then(result => {
            
                result.rows.forEach(element => {
                    element.publish_date_f = moment(element.publish_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                });
                this.outrasNoticias = result.rows;
                this.waiting = false;
            })
        }
    }
}
</script>
