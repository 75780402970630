import axios from 'axios';
import configs from '../configs'

const getCategories = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/categories`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getAlbuns = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/albums`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const saveCategory = async (name, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/categories`,
            { name },
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const deleteCategory = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/categories/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getNews = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/news`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getAllNews = async (token) => {
    const headers = {
        'app-authorization': `${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/news/list/all?orderby=id-desc`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getNewsId = async (id, token) => {
    const headers = {
        'app-authorization': `${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/news/show/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getNewsById = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/news/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const save = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/news`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const edit = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .put(
            `${configs.novaAPI.BASE_URL}/site/news/${data.id}`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const remove = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/news/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getAttachments = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/attachments`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const saveAttachment = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/attachments`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const removeAttachment = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/attachments/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getAttachmentTypes = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/attachments-type`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const saveAttachmentType = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/attachments-type`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const removeAttachmentType = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/attachments-type/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

const getQuestions = async (token) => {
    const headers = {
        'app-authorization': `${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/ask-doctor`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        console.log(e)
    }
}

export default {
    getCategories,
    saveCategory,
    deleteCategory,
    edit,
    save,
    getNews,
    getNewsId,
    getNewsById,
    remove,
    getAttachments,
    saveAttachment,
    removeAttachment,
    getAttachmentTypes,
    saveAttachmentType,
    getAlbuns,
    getAllNews,
    removeAttachmentType,
    getQuestions
}
